import React, { useEffect } from "react";
import Form from "../../Form";
import GoogleMapPoint from "../../GoogleMapPoint";
import CarsChargeTable from "../Common/CarsChargeTable";

const TrichytoPondicherry = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div>
      {/* <iframe
        width="100%"
        height="500"
        frameborder="0"
        src="https://www.google.com/maps/embed/v1/directions?key=AIzaSyC9hVfOnZfTlXxrCuURsOY7cIIp59u8LA0&origin=Chennai,Tamil+Nadu&destination=Bengaluru,Karnataka"
        allowfullscreen
      ></iframe> */}
      <GoogleMapPoint origin={{lat:10.7905,lng:78.7047}} destination={{lat:11.9416,lng:79.8083}}/>

            <div className="form_bg relative bg-fixed flex justify-center px-3">
        <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
          <Form />
        </div>
      </div>
      
      
      <div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
        <h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
        Convenient Trichy to Pondicherry Taxi Service: Your Hassle-Free Journey
Companion        </h3>
        <p className=" pt-2">
        Embark on a hassle-free journey from Trichy to Pondicherry with our convenient and
reliable taxi service. Our goal is to provide you with a seamless travel experience,
ensuring your trip is comfortable, safe, and enjoyable.        </p>


        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          Doorstep Pickup and Drop-off:
        </h3>
        <p className=" pt-2">
        Punctuality is our commitment. Our drivers will arrive
promptly at your specified location in Trichy and ensure you reach Pondicherry on time,
without any unnecessary delays.        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          Comfortable and Well-Maintained Vehicles:
        </h3>
        <p className=" pt-2">
        Choose from a fleet of well-maintained cars, including
sedans, SUVs, and spacious vehicles suitable for groups. Our vehicles are equipped to
provide a comfortable ride throughout your journey.        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          Professional and Reliable Drivers:
        </h3>
        <p className=" pt-2">
        Our experienced drivers are trained to prioritize your safety
and comfort. They are familiar with the best routes between Trichy and Pondicherry,
ensuring a smooth and efficient travel experience.        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          Transparent Pricing:
        </h3>
        <p className=" pt-2">
        We believe in transparent pricing with no hidden charges.
You'll receive a fair and upfront fare estimate before booking your ride, allowing you to
plan your travel budget effectively.        </p>

        <div className=" my-4 sm:my-6 md:my-10">
        <CarsChargeTable />
        </div>
        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
        Convenient Booking:
        </h3>
        <p className=" pt-2">
        Booking your Trichy to Pondicherry taxi is easy. You can
book online through our website, mobile app, or by contacting our customer service
team. Provide your travel details, and we'll handle the rest        </p>


        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          24/7 Customer Support:
        </h3>
        <p className=" pt-2">
        Whether you need a taxi during the day or night, our services are
available 24/7 to accommodate your travel needs. You can rely on us for a comfortable
and reliable ride at any time.        </p>

        <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
          The Enchanting Journey from Trichy to Pondicherry:
        </h3>
        <p className=" pt-2">
        Make your journey from Trichy to Pondicherry stress-free and enjoyable with our reliable
taxi service. Sit back, relax, and enjoy the scenic landscapes along the way.        </p>

        <p className=" pt-5">
        Contact us today to book your taxi and experience a hassle-free travel experience. We
look forward to serving you and ensuring your trip to Pondicherry is memorable for all
the right reasons.        </p>
     </div>
    </div>
  )
}

export default TrichytoPondicherry
