import React, { useState } from "react";
import OneWay from "./OneWay";
import Roundtrip from "./Roundtrip";

const Form = () => {
    const [fromChange, setFormChange] = useState(false);
    function changeFroms(e) {
        if(e.target.value == 'oneway'){
            setFormChange(false)
        }else{
            setFormChange(true)
        }
    }
  return (
    <div className="">
      <div className="container mx-auto ">
        <h3 className=" mt-4 font-bold text-[30px] sm:text-[40px] md:text-[50px] text-center">
          Book One Way Taxi Service{" "}
        </h3>
        <p className="sm:mt-2 md:mt-4 text-center ">
          {" "}
          We are providing one way taxi, outstation cabs, drop taxi and airport
          taxi service.
        </p>
        <div className="pt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 justify-items-center py-3 pb-5 bg-white rounded-md items-center">
            <label
              className={`font-semibold hover:text-[#000] py-2 ${
                fromChange ? "" : "text-white form_shadow"
              } cursor-pointer`}
              htmlFor="oneway"
              onClick={changeFroms}
            >
              One Way
              <input
                type="radio"
                name="type"
                id="oneway"
                value="oneway"
                className="w-0 h-0"
              />
            </label>
            <label
              className={`font-semibold hover:text-[#000]  py-2 cursor-pointer  ${
                fromChange ? " form_shadow" : ""
              }`}
              htmlFor="round"
              onClick={changeFroms}
            >
              Round Trip
              <input
                type="radio"
                name="type"
                id="round"
                value="round"
                className="w-0 h-0"
              />
            </label>
          </div>
        </div>
        {!fromChange ? <OneWay /> : <Roundtrip />}
      </div>
    </div>
  );
};

export default Form;
