import WhatsappComponent from "./WhatsappComponent";
import PhoneComponent from "./components/PhoneComponent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandCondition from "./pages/TermsandCondition";
import Header from "./components/Header";
import Footer from "./components/footer/Footer";
import ChennaitoCoimbatore from "./components/PopularOutstations/Chennai/ChennaitoCoimbatore";
import ChennaitoBangalore from "./components/PopularOutstations/Chennai/ChennaitoBangalore";
import ChennaitoSalem from "./components/PopularOutstations/Chennai/ChennaitoSalem";
import ChennaitoMadurai from "./components/PopularOutstations/Chennai/ChennaitoMadurai";
import ChennaitoPondicherry from "./components/PopularOutstations/Chennai/ChennaitoPondicherry";
import ChennaitoVellore from "./components/PopularOutstations/Chennai/ChennaitoVellore";
import ChennaitoTrichy from "./components/PopularOutstations/Chennai/ChennaitoTrichy";
import ChennaitoKumbakonam from "./components/PopularOutstations/Chennai/ChennaitoKumbakonam";
import PopularOutstations from "./components/PopularOutstations";
import CoimbatoretoChennai from "./components/PopularOutstations/Coimbatore/CoimbatoretoChennai";
import CoimbatoretoBangalore from "./components/PopularOutstations/Coimbatore/CoimbatoretoBangalore";
import CoimbatoretoSalem from "./components/PopularOutstations/Coimbatore/CoimbatoretoSalem";
import CoimbatoretoMadurai from "./components/PopularOutstations/Coimbatore/CoimbatoretoMadurai";
import CoimbatoretoPondicherry from "./components/PopularOutstations/Coimbatore/CoimbatoretoPondicherry";
import CoimbatoretoVellore from "./components/PopularOutstations/Coimbatore/CoimbatoretoVellore";
import CoimbatoretoTrichy from "./components/PopularOutstations/Coimbatore/CoimbatoretoTrichy";
import Coimbatoretokumbakonam from "./components/PopularOutstations/Coimbatore/Coimbatoretokumbakonam";
import BangaloretoChennai from "./components/PopularOutstations/Bangalore/BangaloretoChennai";
import BangaloretoCoimbatore from "./components/PopularOutstations/Bangalore/BangaloretoCoimbatore";
import BangaloretoSalem from "./components/PopularOutstations/Bangalore/BangaloretoSalem";
import BangaloretoMadurai from "./components/PopularOutstations/Bangalore/BangaloretoMadurai";
import BangaloretoPondicherry from "./components/PopularOutstations/Bangalore/BangaloretoPondicherry";
import BangaloretoVellore from "./components/PopularOutstations/Bangalore/BangaloretoVellore";
import BangaloretoTrichy from "./components/PopularOutstations/Bangalore/BangaloretoTrichy";
import BangaloretoKumbakonam from "./components/PopularOutstations/Bangalore/BangaloretoKumbakonam";
import VelloretoChennai from "./components/PopularOutstations/Vellore/VelloretoChennai";
import VelloretoCoimbatore from "./components/PopularOutstations/Vellore/VelloretoCoimbatore";
import VelloretoBangalore from "./components/PopularOutstations/Vellore/VelloretoBangalore";
import Velloretosalem from "./components/PopularOutstations/Vellore/Velloretosalem";
import VelloretoMaduai from "./components/PopularOutstations/Vellore/VelloretoMaduai";
import VelloretoPondicherry from "./components/PopularOutstations/Vellore/VelloretoPondicherry";
import VelloretoTricy from "./components/PopularOutstations/Vellore/VelloretoTricy";
import VelloretoKumbakonam from "./components/PopularOutstations/Vellore/VelloretoKumbakonam";
import TrichytoChennai from "./components/PopularOutstations/Tichy/TrichytoChennai";
import TrichytoCoimbatore from "./components/PopularOutstations/Tichy/TrichytoCoimbatore";
import TrichytoBangalore from "./components/PopularOutstations/Tichy/TrichytoBangalore";
import TrichytoSalem from "./components/PopularOutstations/Tichy/TrichytoSalem";
import TrichytoMadurai from "./components/PopularOutstations/Tichy/TrichytoMadurai";
import TrichytoPondicherry from "./components/PopularOutstations/Tichy/TrichytoPondicherry";
import TrichytoVellore from "./components/PopularOutstations/Tichy/TrichytoVellore";
import Trichytokumbakonam from "./components/PopularOutstations/Tichy/Trichytokumbakonam";
import Kumbakonamtochennai from "./components/PopularOutstations/Kumbakonam/Kumbakonamtochennai";
import KumbakonamtoCoimbatore from "./components/PopularOutstations/Kumbakonam/KumbakonamtoCoimbatore";
import KumbakonamtoBangalore from "./components/PopularOutstations/Kumbakonam/KumbakonamtoBangalore";
import KumbakonamtoSalem from "./components/PopularOutstations/Kumbakonam/KumbakonamtoSalem";
import KumbakonamtoMadurai from "./components/PopularOutstations/Kumbakonam/KumbakonamtoMadurai";
import KumbakonamtoPondicherry from "./components/PopularOutstations/Kumbakonam/KumbakonamtoPondicherry";
import KumbakonamtoVellore from "./components/PopularOutstations/Kumbakonam/KumbakonamtoVellore";
import KumbakonamtoTrichy from "./components/PopularOutstations/Kumbakonam/KumbakonamtoTrichy";
import SalemtoChennai from "./components/PopularOutstations/Salem/SalemtoChennai";
import SalemtoCoimbatore from "./components/PopularOutstations/Salem/SalemtoCoimbatore";
import Salemtobanglore from "./components/PopularOutstations/Salem/Salemtobanglore";
import SalemtoMadurai from "./components/PopularOutstations/Salem/SalemtoMadurai";
import SalemtoPondicherry from "./components/PopularOutstations/Salem/SalemtoPondicherry";
import Salemtovellore from "./components/PopularOutstations/Salem/Salemtovellore";
import SalemtoTrichy from "./components/PopularOutstations/Salem/SalemtoTrichy";
import SalemtoKumbakoam from "./components/PopularOutstations/Salem/SalemtoKumbakoam";
import MaduraitoChennai from "./components/PopularOutstations/Madurai/MaduraitoChennai";
import MaduraitoCoimbatore from "./components/PopularOutstations/Madurai/MaduraitoCoimbatore";
import MaduraitoBanglore from "./components/PopularOutstations/Madurai/MaduraitoBanglore";
import MaduraitoSalem from "./components/PopularOutstations/Madurai/MaduraitoSalem";
import MaduraitoPondicherry from "./components/PopularOutstations/Madurai/MaduraitoPondicherry";
import MaduraitoVellore from "./components/PopularOutstations/Madurai/MaduraitoVellore";
import MaduraitoTrichy from "./components/PopularOutstations/Madurai/MaduraitoTrichy";
import MaduraitoKumbakonam from "./components/PopularOutstations/Madurai/MaduraitoKumbakonam";
import PondicherrytoChennai from "./components/PopularOutstations/Pondicherry/PondicherrytoChennai";
import PondicherrytoCoimbatore from "./components/PopularOutstations/Pondicherry/PondicherrytoCoimbatore";
import PondicherrytoBanglore from "./components/PopularOutstations/Pondicherry/PondicherrytoBanglore";
import Pondicherrytosalem from "./components/PopularOutstations/Pondicherry/Pondicherrytosalem";
import PondicherrytoMadurai from "./components/PopularOutstations/Pondicherry/PondicherrytoMadurai";
import PondicherrytoVellore from "./components/PopularOutstations/Pondicherry/PondicherrytoVellore";
import PondicherrytoTrichy from "./components/PopularOutstations/Pondicherry/PondicherrytoTrichy";
import PondicherrytoKumbakonam from "./components/PopularOutstations/Pondicherry/PondicherrytoKumbakonam";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsandCondition />}
          ></Route>

          <Route path="/one-way-drop-taxi-chennai-to-coimbatore" element={<ChennaitoCoimbatore />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-bangalore" element={<ChennaitoBangalore />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-salem" element={<ChennaitoSalem />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-madurai" element={<ChennaitoMadurai />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-pondicherry" element={<ChennaitoPondicherry />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-vellore" element={<ChennaitoVellore />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-trichy" element={<ChennaitoTrichy />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-kumbakonam" element={<ChennaitoKumbakonam />}></Route>
    
          <Route path="/one-way-drop-taxi-coimbatore-to-chennai" element={<CoimbatoretoChennai />}></Route>
          <Route path="/one-way-drop-taxi-coimbatore-to-bangalore" element={<CoimbatoretoBangalore />}></Route>
          <Route path="/one-way-drop-taxi-coimbatore-to-salem" element={<CoimbatoretoSalem />}></Route>
          <Route path="/one-way-drop-taxi-coimbatore-to-madurai" element={<CoimbatoretoMadurai />}></Route>
          <Route path="/one-way-drop-taxi-coimbatore-to-pondicherry" element={<CoimbatoretoPondicherry />}></Route>
          <Route path="/one-way-drop-taxi-coimbatore-to-vellore" element={<CoimbatoretoVellore />}></Route>
          <Route path="/one-way-drop-taxi-coimbatore-to-trichy" element={<CoimbatoretoTrichy />}></Route>
          <Route path="/one-way-drop-taxi-coimbatore-to-kumbakonam" element={<Coimbatoretokumbakonam />}></Route>

          <Route path="/one-way-drop-taxi-bangalore-to-chennai" element={<BangaloretoChennai />}></Route>
          <Route path="/one-way-drop-taxi-bangalore-to-coimbatore" element={<BangaloretoCoimbatore />}></Route>
          <Route path="/one-way-drop-taxi-bangalore-to-salem" element={<BangaloretoSalem />}></Route>
          <Route path="/one-way-drop-taxi-bangalore-to-madurai" element={<BangaloretoMadurai />}></Route>
          <Route path="/one-way-drop-taxi-bangalore-to-pondicherry" element={<BangaloretoPondicherry />}></Route>
          <Route path="/one-way-drop-taxi-bangalore-to-vellore" element={<BangaloretoVellore />}></Route>
          <Route path="/one-way-drop-taxi-bangalore-to-trichy" element={<BangaloretoTrichy />}></Route>
          <Route path="/one-way-drop-taxi-bangalore-to-kumbakonam" element={<BangaloretoKumbakonam />}></Route>

          <Route path="/one-way-drop-taxi-salem-to-chennai" element={<SalemtoChennai />}></Route>
          <Route path="/one-way-drop-taxi-salem-to-coimbatore" element={<SalemtoCoimbatore />}></Route>
          <Route path="/one-way-drop-taxi-salem-to-bangalore" element={<Salemtobanglore />}></Route>
          <Route path="/one-way-drop-taxi-salem-to-madurai" element={<SalemtoMadurai />}></Route>
          <Route path="/one-way-drop-taxi-salem-to-pondicherry" element={<SalemtoPondicherry />}></Route>
          <Route path="/one-way-drop-taxi-salem-to-vellore" element={<Salemtovellore />}></Route>
          <Route path="/one-way-drop-taxi-salem-to-trichy" element={<SalemtoTrichy />}></Route>
          <Route path="/one-way-drop-taxi-salem-to-kumbakonam" element={<SalemtoKumbakoam />}></Route>


          <Route path="/one-way-drop-taxi-madurai-to-chennai" element={<MaduraitoChennai />}></Route>
          <Route path="/one-way-drop-taxi-madurai-to-Ccimbatore" element={<MaduraitoCoimbatore />}></Route>
          <Route path="/one-way-drop-taxi-madurai-to-bangalore" element={<MaduraitoBanglore />}></Route>
          <Route path="/one-way-drop-taxi-madurai-to-salem" element={<MaduraitoSalem />}></Route>
          <Route path="/one-way-drop-taxi-madurai-to-pondicherry" element={<MaduraitoPondicherry />}></Route>
          <Route path="/one-way-drop-taxi-madurai-to-vellore" element={<MaduraitoVellore />}></Route>
          <Route path="/one-way-drop-taxi-madurai-to-trichy" element={<MaduraitoTrichy />}></Route>
          <Route path="/one-way-drop-taxi-madurai-to-kumbakonam" element={<MaduraitoKumbakonam />}></Route>

          <Route path="/one-way-drop-taxi-pondicherry-to-chennai" element={<PondicherrytoChennai />}></Route>
          <Route path="/one-way-drop-taxi-pondicherry-to-coimbatore" element={<PondicherrytoCoimbatore />}></Route>
          <Route path="/one-way-drop-taxi-pondicherry-to-bangalore" element={<PondicherrytoBanglore />}></Route>
          <Route path="/one-way-drop-taxi-pondicherry-to-salem" element={<Pondicherrytosalem />}></Route>
          <Route path="/one-way-drop-taxi-pondicherry-to-madurai" element={<PondicherrytoMadurai />}></Route>
          <Route path="/one-way-drop-taxi-pondicherry-to-vellore" element={<PondicherrytoVellore />}></Route>
          <Route path="/one-way-drop-taxi-pondicherry-to-trichy" element={<PondicherrytoTrichy />}></Route>
          <Route path="/one-way-drop-taxi-pondicherry-to-kumbakonam" element={<PondicherrytoKumbakonam />}></Route>

          <Route path="/one-way-drop-taxi-vellore-to-chennai" element={<VelloretoChennai />}></Route>
          <Route path="/one-way-drop-taxi-vellore-to-coimbatore" element={<VelloretoCoimbatore />}></Route>
          <Route path="/one-way-drop-taxi-vellore-to-bangalore" element={<VelloretoBangalore />}></Route>
          <Route path="/one-way-drop-taxi-vellore-to-salem" element={<Velloretosalem />}></Route>
          <Route path="/one-way-drop-taxi-vellore-to-madurai" element={<VelloretoMaduai />}></Route>
          <Route path="/one-way-drop-taxi-vellore-to-pondicherry" element={<VelloretoPondicherry />}></Route>
          <Route path="/one-way-drop-taxi-vellore-to-trichy" element={<VelloretoTricy />}></Route>
          <Route path="/one-way-drop-taxi-vellore-to-kumbakonam" element={<VelloretoKumbakonam />}></Route>

          <Route path="/one-way-drop-taxi-trichy-to-chennai" element={<TrichytoChennai />}></Route>
          <Route path="/one-way-drop-taxi-trichy-to-coimbatore" element={<TrichytoCoimbatore />}></Route>
          <Route path="/one-way-drop-taxi-trichy-to-bangalore" element={<TrichytoBangalore />}></Route>
          <Route path="/one-way-drop-taxi-trichy-to-salem" element={<TrichytoSalem />}></Route>
          <Route path="/one-way-drop-taxi-trichy-to-madurai" element={<TrichytoMadurai />}></Route>
          <Route path="/one-way-drop-taxi-trichy-to-pondicherry" element={<TrichytoPondicherry />}></Route>
          <Route path="/one-way-drop-taxi-trichy-to-vellore" element={<TrichytoVellore />}></Route>
          <Route path="/one-way-drop-taxi-trichy-to-kumbakonam" element={<Trichytokumbakonam />}></Route>

          <Route path="/one-way-drop-taxi-kumbakonam-to-chennai" element={<Kumbakonamtochennai/>}></Route>
          <Route path="/one-way-drop-taxi-kumbakonam-to-coimbatore" element={<KumbakonamtoCoimbatore/>}></Route>
          <Route path="/one-way-drop-taxi-kumbakonam-to-bangalore" element={<KumbakonamtoBangalore/>}></Route>
          <Route path="/one-way-drop-taxi-kumbakonam-to-salem" element={<KumbakonamtoSalem/>}></Route>
          <Route path="/one-way-drop-taxi-kumbakonam-to-madurai" element={<KumbakonamtoMadurai/>}></Route>
          <Route path="/one-way-drop-taxi-kumbakonam-to-pondicherry" element={<KumbakonamtoPondicherry/>}></Route>
          <Route path="/one-way-drop-taxi-kumbakonam-to-vellore" element={<KumbakonamtoVellore/>}></Route>
          <Route path="/one-way-drop-taxi-kumbakonam-to-trichy" element={<KumbakonamtoTrichy/>}></Route>
</Routes>
        <PopularOutstations />
        <Footer />
        <WhatsappComponent />
        <PhoneComponent />
      </BrowserRouter>
    </>
  );
}

export default App;
