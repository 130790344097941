import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint';
import Form from '../../Form';
import CarsChargeTable from '../Common/CarsChargeTable';


const CoimbatoretoChennai = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
    {/* <iframe
      width="100%"
      height="500"
      frameborder="0"
      src="https://www.google.com/maps/embed/v1/directions?key=AIzaSyC9hVfOnZfTlXxrCuURsOY7cIIp59u8LA0&origin=Chennai,Tamil+Nadu&destination=Bengaluru,Karnataka"
      allowfullscreen
    ></iframe> */}
    <GoogleMapPoint origin={{lat:11.0168,lng:76.9558}} destination={{lat:13.0827,lng:80.2707}}/>

          <div className="form_bg relative bg-fixed flex justify-center px-3">
      <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
        <Form />
      </div>
    </div>
    
    
    <div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
      <h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
      Convenient Coimbatore to Chennai Taxi Service: Your Hassle-Free Travel
      Solution
      </h3>
      <p className=" pt-2">
      Are you planning a trip from Coimbatore to Chennai and looking for a reliable taxi
      service? Look no further! Our taxi service offers a convenient and comfortable travel
      solution for your journey between these two vibrant cities.      </p>

      <h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
        Discovering the Outstation Taxi Advantage:
      </h3>
      <p className=" pt-2">
      With our experienced 
      and professional drivers, you can enjoy a safe and smooth ridefrom Coimbatore to Chennai 
      and vice versa. Whether you're traveling for business,leisure, or any other purpose, our 
      taxis are well-maintained, clean, and equipped to ensure your comfort throughout the journey. 
        </p>

      <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
        Doorstep Pickup and Drop-off:
      </h3>
      <p className=" pt-2">
      We understand the value of time, and our drivers
      are punctual, ensuring that you reach your destination on time without any
      delays.      </p>

      <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
        Comfortable and Well-Maintained Vehicles:
      </h3>
      <p className=" pt-2">
      Our fleet consists of a range of vehicles, including
      sedans, SUVs, and luxury cars, allowing you to choose according to your
      preferences and group size.      </p>

      <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
        Professional and Reliable Drivers:
      </h3>
      <p className=" pt-2">
      Our drivers are experienced, licensed, and well-versed
      with the routes between Coimbatore and Chennai, ensuring a smooth and safe
      journey.      </p>

      <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
        Transparent Pricing:
      </h3>
      <p className=" pt-2">
      We offer transparent and competitive pricing with no
   hidden charges, providing you with cost-effective travel options.
24/7 Availability: Whether you need a taxi during the day or night, our service
operates 24/7, allowing you to book your ride at your convenience.
      </p>

      <div className=" my-4 sm:my-6 md:my-10">
      <CarsChargeTable />
      </div>

      <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
        24/7 Customer Support:
      </h3>
      <p className=" pt-2">
        To cater to any unforeseen circumstances or address any queries or
        concerns, Outstation Taxi provides round-the-clock customer support.
        You can reach out to their dedicated team for assistance or guidance
        at any time during your journey.
      </p>

      <h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
        The Enchanting Journey from Coimbatore to Chennai:
      </h3>
      <p className=" pt-2">
      Booking our Coimbatore to Chennai taxi service is easy. Simply contact us or use our
      online booking platform to reserve your taxi in advance. Provide us with your travel
     details, and we will take care of the rest, ensuring a hassle-free and enjoyable journey
      for you. </p>
      <p className=" pt-5">
      Don't let travel hassles spoil your trip. Choose our reliable taxi service for a
      comfortable and convenient ride from Coimbatore to Chennai and experience travel at
      its best. We look forward to serving you and making your journey memorable.     
      </p>
    </div>
  </div>
  )
}

export default CoimbatoretoChennai
