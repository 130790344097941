import React from 'react'
import PopularComponents from './PopularComponents'
import ps1 from "../assets/places/ps1.jpg";
import ps2 from "../assets/places/ps2.jpg";
import ps3 from "../assets/places/ps3.jpg";
import ps4 from "../assets/places/ps4.jpg";
import ps5 from "../assets/places/ps5.jpg";
import ps6 from "../assets/places/ps6.jpg";

const PopularRoutes = () => {
   
    return (
        <div className='bg-[#f4f5f8]' id='pop_routes'>
            <div className='container py-5 mx-auto px-0 sm:px-4 md:px-8 lg:px-16'>
                <div className='mt-3 text-6xl uppercase font-medium text-[#0f2454]'>
                    Popular routes
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 items-center gap-x-10 tours3 mt-6'>
                    <PopularComponents img_link={ps1} place_name="Bangalore" amount="₹4950 / Plus Toll, Permit Extra"  km="348 KM" desc_time="6 hour 57 minutes" time="7 Hr 30 Min" user=" 1000+" rating="9.5 Superb" />
                    <PopularComponents img_link={ps2} place_name="Coimbatore" amount="₹7130 / Plus Toll Extra"  km="505 KM" desc_time="9 hour 25 minutes" time="9 Hr 30 Min" user="2000+" rating="9.8 Superb" />
                    <PopularComponents img_link={ps3} place_name="Pondicherry" amount="₹2415 / Plus Toll, Permit Extra" km="155 KM" desc_time="3 hour 35 minutes" time="3 Hr 50 Min" user="2500+"  rating="9.5 Superb" />
                    <PopularComponents img_link={ps4} place_name="Madurai" amount="₹6545 / Plus Toll Extra"  km="465 KM" desc_time="8 hour 15 minutes" time="8 Hr 20 Min"  user="2600+" rating="9.5 Superb"/>
                    <PopularComponents img_link={ps5} place_name="Thanjavur" amount="₹6545 / Plus Toll Extra" km="345 KM" desc_time="6 hour 45 minutes" time="6 Hr 50 Min" user="1500+" rating="9.3 Superb"  />
                    <PopularComponents img_link={ps6} place_name="Rameswaram" amount="₹7780 / Plus Toll Extra" km="560 KM" desc_time="6 hour 45 minutes" time="9 Hr 45 Min" user="2100+"  rating="9.0 Superb" />
                </div>
            </div>
        </div>
    )
}

export default PopularRoutes