import React from 'react'
import { AiOutlineClockCircle , AiOutlineUser, AiOutlineSmile } from 'react-icons/ai';
import {CiLocationOn} from 'react-icons/ci';

const PopularComponents = ({ img_link , place_name ,amount , km , desc_time , time, user,rating }) => {
    const divStyle = {
        backgroundImage: `url(${img_link})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
    };
    return (
        <div className="square-flip">
            <div className="square bg-img" style={divStyle}> <span className="category"><a href="#" className=' uppercase'>{place_name}</a></span>
                <div className="square-container d-flex align-items-end justify-content-end">
                    <div className="box-title bottom-5 absolute right-5">
                        <h4 className='text-xl font-semibold'>{place_name}</h4>
                        <h6>{amount}</h6>
                    </div>
                </div>
                <div className="flip-overlay"></div>
            </div>
            <div className="square2 bg-white">
                <div className="square-container2">
                    <h5 className='text-[#0f2454] text-2xl font-semibold'>Chennai to {place_name} Taxi</h5>
                    <h6 className='mt-4'>{amount}</h6>
                    <p>The distance from Chennai to {place_name} is about {km}. It take approximately {desc_time} to complete the journey.</p>
                    <div className="grid grid-cols-2 tour-list mb-30 mt-5">
                        <div className="col col-md-6">
                            <ul>
                                <li className='flex items-center gap-x-1'><AiOutlineClockCircle />{time}</li>
                                <li className='mt-5 flex items-center gap-x-1'><AiOutlineUser size={20} /> {user}</li>
                            </ul>
                        </div>
                        <div className="col col-md-6">
                            <ul>
                                <li className='flex items-center gap-x-1'><CiLocationOn size={20}/>Chennai</li>
                                <li className='mt-5 flex items-center gap-x-1'><AiOutlineSmile  size={20}/> {rating}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="btn-line mt-5"><a href="#book_taxi_form">Book Now</a></div>
                </div>
            </div>
        </div>
    )
}

export default PopularComponents