import React from 'react'
import about from "../assets/about.jpg";
const About = () => {
    return (
        <div className='bg-[#ffc9cb]' id='about'>
            <div className='container py-5 mx-auto px-0 sm:px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-14 gap-x-28 items-center'>
                    <div className='w-full md:w-auto'>
                        <div>
                            <img src={about} alt="about" className='mx-auto w-[500px] car_outline' />
                        </div>
                    </div>
                    <div className='mt-20 md:mt-0 px-3'>
                        <h4 className='text-[#241D92] text-5xl font-medium'>About</h4>
                        <div className='text-[#e30d16] text-3xl font-semibold mt-4'>Lovely Cabs - TAXI SERVICES</div>
                        <div className=' text-black opacity-60 text-xl mt-5'>Lovely Cabs is an Outstation One Way Drop Cab Booking Service Provider from Chennai for All Over Tamil Nadu for over 5 years. Book Your Outstation One Way Drop Taxi with Lovely Cabs Taxi and Enjoy Your Journey.</div>
                        <div className='mt-8 opacity-60 text-3xl founder_style'>Founder</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About