import React from 'react'
import banner from "../assets/form_banner.jpg";
import cars from "../assets/form_cars.png";
import Form from './Form';
const BookingForm = () => {



    const divStyle = {
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
    };
  

    return (
        <div className='form_bg relative bg-fixed' style={divStyle} >
            <div className='container py-28 mx-auto px-0 sm:px-4 md:px-8 lg:px-16 relative z-10'>
                <div className='grid grid-cols-1  md:grid-cols-1 lg:grid-cols-2 gap-y-6'>
                    <div className='w-full md:w-4/5'>
                        <img src={cars} alt="cars" className='w-[250px]' />
                        <div className=' text-6xl text-white font-bold mt-4'>One Way Drop Taxi Booking</div>
                        <div className='text-5xl mt-5 font-semibold'>at Lovely Cabs - SUV Taxi Services</div>
                        <div className='mt-4 text-lg opacity-75'> A Hassle-Free Journey all over Tamil Nadu, Andhra, Telangana, Karnataka and Kerala</div>
                        <div className=' text-white font-bold text-lg mt-10'>
                            <a href="tel:+919626352352" className=' rounded-md bg-[#e30d16] hover:bg-black transition-all ease-linear px-3 py-2'>Call +919626352352</a>
                        </div>
                    </div>
                    <div className='bg-white  rounded-lg px-4  pb-4 ' id='book_taxi_form'>
                        <Form />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingForm