import React from 'react'
import sedan1 from "../assets/cars/sed1.png";
import sedan2 from "../assets/cars/sed2.png";
import sedan3 from "../assets/cars/sed3.jpeg";
// import sedan4 from "../assets/cars/sed4.jpg";
// import sedan5 from "../assets/cars/sed5.jpg";

import suv1 from "../assets/cars/suv1.jpg";
import suv2 from "../assets/cars/suv2.jpg";
import suv3 from "../assets/cars/suv3.jpg";
// import suv4 from "../assets/cars/suv4.jpg";
// import suv5 from "../assets/cars/suv5.jpg";
import SedanCabs from '../SedanCabs';
import { TypeAnimation } from 'react-type-animation';

const Cars = () => {

    return (
        <div className=' container py-5 mx-auto px-0 sm:px-4 md:px-8 lg:px-16' id='cars'>
             <div className='grid sm:grid-cols-1 gap-y-3 md:grid-cols-2 items-center gap-x-3'>
                <div>
                    <div>AVAILABLE</div>
                    <div className=' text-6xl font-semibold text-[#0C087E]'>SEDAN CABS</div>
                </div>
                <div className='text-[#e30d16] text-xl font-semibold typing_text'>
                    <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed out once, initially
                            'Starting from Rs 400 Driver Beta',
                            1000, // wait 1s before replacing "Mice" with "Hamsters"
                            'Starting from Rs 14 / Km',
                            1000,
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{ fontSize: '2em', display: 'inline-block' }}
                        repeat={Infinity}
                    />
                </div>
            </div> 
            <div className='mt-5'>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 cars_grid_parent'>
                    <SedanCabs name="TOYOTA ETIOS" img_link={sedan1} oneway="14" roundtrip="12" />
                    <SedanCabs name="SWIFT DZIRE TOUR" img_link={sedan2} oneway="14" roundtrip="12"/>
                    <SedanCabs name="HYUNDAI XCENT" img_link={sedan3} oneway="14" roundtrip="12"/>
                    {/* <SedanCabs name="NISSAN SUNNY" img_link={sedan4} />
                    <SedanCabs name="MAHINDRA VERITO" img_link={sedan5} /> */}
                </div>
            </div>
            <div className='grid grid-cols-1 gap-y-3 md:grid-cols-2 items-center mt-10'>
                <div>
                    <div>AVAILABLE</div>
                    <div className=' text-6xl font-semibold text-[#0C087E]'>SUV CABS</div>
                </div>
                <div className='text-[#e30d16] text-xl font-semibold typing_text'>
                    <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed out once, initially
                            'Starting from Rs 400 Driver Beta',
                            1000, // wait 1s before replacing "Mice" with "Hamsters"
                            'Starting from Rs 18 / Km',
                            1000,
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{ fontSize: '2em', display: 'inline-block' }}
                        repeat={Infinity}
                    />
                </div>
            </div>
            <div className='mt-5'>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 cars_grid_parent'>
                    <SedanCabs name="TOYOTA INNOVA" img_link={suv1}  oneway="19" roundtrip="17"/>
                    <SedanCabs name="XYLO & MARAZZO" img_link={suv2} oneway="19" roundtrip="17" />
                    <SedanCabs name="MARUTI ERTIGA" img_link={suv3}  oneway="19" roundtrip="17"/>
                    {/* <SedanCabs name="RENAULT LODGY" img_link={suv4} />
                    <SedanCabs name="CHEVROLET ENJOY" img_link={suv5} /> */}
                </div>
            </div>
        </div>
    )
}

export default Cars