import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  Marker,
} from "@react-google-maps/api";

const GoogleMapPoint = ({ origin, destination }) => {
  const [directions, setDirections] = useState(null);
  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: { lat: origin?.lat, lng: origin?.lng }, // Chennai
        destination: { lat: destination?.lat, lng: destination?.lng }, // Coimbatore
        travelMode: "DRIVING",
      },
      (result, status) => {
        if (status === "OK") {
          setDirections(result);
        } else {
          console.error( `Error fetching directions ${result}` );
        }
      }
    );
  }, []);
  return (
    <div>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={{ lat: origin?.lat, lng: origin?.lng }} // Chennai
        zoom={7}
      >
        <Marker position={{ lat: origin?.lat, lng: origin?.lng }} label="A" />
        <Marker position={{ lat: destination?.lat, lng: destination?.lng }} label="B" />
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapPoint;
