import React from 'react'
import { BsFillTelephoneFill } from 'react-icons/bs';

const SedanCabs = ({ name, img_link, oneway, roundtrip }) => {
    return (
        <div className='px-3 py-10 cars_parent'>
            <img src={img_link} alt="sedan1" className=' rounded-lg w-40 object-cover mx-auto' />
            <div className='text-[#e30d16] font-semibold text-center mt-4 text-xl car_name'>{name}</div>
            {oneway && <div className=' text-center font-semibold mt-3'><span className=' align-middle'>One Way :</span> <span className='font-bold text-xl align-middle'>Rs .{oneway}</span></div>}
            {roundtrip && <div className=' text-center font-semibold mt-3'><span className=' align-middle'>Round Trip :</span> <span className='font-bold text-xl align-middle'>Rs .{roundtrip}</span></div>}
            <div className='text-center mt-4'>
                <a href="#book_taxi_form" className='bg-[#009ee3] text-xl px-4 py-1 rounded-2xl border-[#FFED00] text-[#FFED00] font-medium border-4 flex  w-max justify-center mx-auto items-center'><BsFillTelephoneFill size={25} className="pr-2" /> <span>Book Now</span></a>
            </div>
        </div>
    )
}

export default SedanCabs